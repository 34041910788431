:root {
  --color-bg: #f5f1ed;
  --color-fg: #252323;
  --color-accent: #009f00;
  /* --color-accent: #751515; */
  /* --color-bg: #7d98a1; */
  /* --color-fg: #1c2321; */
}

:root.dark-mode {
  --color-bg: #252323;
  --color-fg: #f5f1ed;
  --color-accent: #00ff00;
  /* --color-bg: #233d4d; */
  /* --color-fg: #c6caed; */
  /* --color-accent: #fcca46; */
  /* --color-accent: #e15554; */
  /* --color-bg: #1c2321; */
  /* --color-fg: #7d98a1; */
}

body {
  background-color: var(--color-bg);
}

code {
  color: var(--color-accent);
}

h1,
h2,
h3 {
  color: var(--color-accent);
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

a,
p,
div,
button {
  color: var(--color-fg);
}

a:hover {
  background-color: var(--color-fg);
  color: var(--color-bg);
}

.App {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  position: relative;
}

.dark-mode-toggle {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.blog-post {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  text-align: left;
}

.table-of-contents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.table-of-contents ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

button {
  background-color: var(--color-fg);
  color: var(--color-bg);
  box-shadow: none;
  outline: none;
  border: 1px solid var(--color-bg);
  padding: 0.5rem;
}

button:hover {
  background-color: var(--color-accent);
  cursor: pointer;
}
